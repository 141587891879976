<template>
  <div id="personalAwardIndex">
    <!-- 查询 -->
    <div class="search-input-div">
      <van-search class="search-input" v-model="query.search" show-action placeholder="请输入关键词查询" @search="toQuery">
        <template #action>
          <div @click="toQuery">搜索</div>
        </template>
      </van-search>
    </div>
    <!-- 卡片 -->
    <van-list v-model="loading" :immediate-check="false" offset="30" :finished="finished" finished-text="没有更多了" @load="onLoad">
      <van-empty v-if="showEmpty" description="暂无数据" />
      <van-cell-group v-else inset style="margin-top:15px" v-for="(row,index)  in data" :key="index">
        <van-cell>
          <template #title>
            <span style="font-size:16px;color:#333333"><b>{{row.stuName+"  "}}</b></span>
            <span style="font-size:14px;color:#999999">{{row.stuNo}}</span>
          </template>
          <template #label>
            <div class="custom-centent margin6 hidden-text95">
              <svg-icon icon-class="rq" size="18" /> {{row.collName}}
            </div>
            <div class="custom-centent margin6 hidden-text" style="width:60%">
              <svg-icon icon-class="bfdw" size="18" /> {{row.sqecName}}
            </div>
            <div class="custom-centent margin6 hidden-text" style="width:60%">
              <svg-icon icon-class="bfdw" size="18" /> {{row.className}}
            </div>
          </template>
          <template #right-icon>
            <div class="button-apply-div" v-if="lxCode.startsWith('info') || lxCode.startsWith('expand')">
              <van-button class="table-edit-btn apply-btn" @click="toEdit(row)" plain round size="small" type="primary">班级配置</van-button>
            </div>
          </template>
        </van-cell>
        <van-cell>
          <template #title>
            <span class="hidden-text95" style="line-height: 35px;">
              学籍状态：<span class="success-color ">{{row.stuStatusName}} </span>
            </span>
          </template>
          <template #right-icon>
            <span class="table-detail-btn detail-pos" @click="toStuDeatil(row)" style="color:#B2B2B2"> 学生主页
              <van-icon name="arrow" />
            </span>
          </template>
        </van-cell>
      </van-cell-group>
    </van-list>
    <switch-role @change="toQuery()"></switch-role>
  </div>
</template>

<script>
import initData from "@/mixins/initData";
export default {
  name: "StuInfo",
  mixins: [initData],
  dicts: ["xj_shzt"],
  data() {
    return {
      lxCode: "info"
    }
  },
  created() {
    this.$nextTick(() => {
      let flag = this.$route.fullPath.lastIndexOf('/')
      this.lxCode = this.$route.fullPath.substring(flag + 1)
    })
  },
  methods: {
    beforeInit() {

      this.url = "api/xj/stuinfos";
      this.sort = [
        'gradeNo,desc',
        'collNo,asc',
        'sqecCode,asc',
        'classNo,asc',
        'stuName,asc',
      ];
      // 初始化条件
      this.beforeRefresh()
      return true;
    },
    beforeRefresh() {
      let flag = this.$route.fullPath.lastIndexOf('/')
      let lxCode = this.$route.fullPath.substring(flag + 1)
      if (lxCode.startsWith('info')) {
        // 学生
        this.query.expandStu = '0'
        this.query.gradeNos = this.genQueryParmOfGradeNo()
      } else if (lxCode.startsWith('graduate')) {
        // 毕业生
        this.query.gradeNoNot = this.genQueryParmOfGradeNo()
      } else if (lxCode.startsWith('expand')) {
        // 扩招生
        this.query.gradeNos = this.genQueryParmOfGradeNo()
        this.query.expandStu = '1'
      }
    },
    genQueryParmOfGradeNo() {
      let gradeNos = []
      let date = new Date()
      let year = date.getFullYear()
      let nowMonth = date.getMonth() + 1
      if (nowMonth > 7) {
        gradeNos.push(parseInt(year))
        gradeNos.push(parseInt(year) - 1)
        gradeNos.push(parseInt(year) - 2)
      } else {
        gradeNos.push(parseInt(year) - 1)
        gradeNos.push(parseInt(year) - 2)
        gradeNos.push(parseInt(year) - 3)
      }
      return gradeNos
    },
    toStuDeatil(data) {
      this.$router.push({
        path: "/js/stu/detail",
        query: { stu: btoa(data.stuNo) },
      });
    },
    // 编辑
    toEdit(data) {
      this.$router.push({
        path: "/js/stuInfo/class/form",
        query: { stuNo: data.stuNo },
      });
    },
    stuDisableBtn() {
      return false;
    },
  },
}
</script>
<style lang="scss" scoped>
.ckeckbox-st {
  font-size: 21px;
  top: 50%;
  position: absolute;
  right: 5%;
}

.cancel {
  color: #999999;
  position: absolute;
  top: 20px;
  right: 30px;
}
.apply-btn {
  top: 45%;
  width: 80px;
}
.xjzt {
  line-height: 0px;
}
</style>